<template>
  <div
    class="com-product-ProductItem d-flex flex-column product-bg"
    :class="config.className"
  >
    <div class="header">
      <h1>
        <img
          src="../../../public/img/pro-icon.png"
          style="height: 25px; margin-top: -5px"
        />
        {{ config.name }}
      </h1>
    </div>
    <div class="body">
      <p>
        {{ config.desc }}
      </p>
      <ul>
        <li v-for="(item, index) in config.list" :key="index">
          <cs-icon
            name="icon-jieshaojiantou"
            size="15"
            class="fw-bolder text-primary"
          />
          {{ item }}
        </li>
      </ul>
      
    </div>
    <div class="footer">
      <!-- <span>
        <label>¥</label><b>{{ config.price }}</b>
        起
      </span> -->
      <button class="btnAdd text-white" @click="submitClick">体验申请</button>
    </div>
    <ProductBuy ref="buy"></ProductBuy>
   
  </div>
</template>
<script>
import ProductBuy from './ProductBuy.vue';
export default {
  components: {
    ProductBuy
  },
  props: {
    config: {},
  },
  data() {
    return {
     
    };
  },
  inject: ["fatherMethod"],
  methods: {
    submitClick() {
      // const { id } = this.config;
      // this.$router.push({
      //   path: "/product/addOrder",
      //   query: {
      //     id,
      //   },
      // });
      if(this.getBrowser == 'pc'){
           this.$refs.buy.openClick()
      }else{
           this.fatherMethod()
      }
   
    },
  },
};
</script>
<style lang="less" scoped>
 @media screen and (min-width: 320px) and (max-width: 1024px) {
  .com-product-ProductItem {
      width: 90%;
      margin-left:5%;
      height: 450px;
      padding: 40px 32px;
      background: #ffffff;
      border: 1px solid rgba(0, 164, 151, 0.1);
      box-shadow: 0px 0px 11px 0px rgba(0, 132, 121, 0.1);
      border-radius: 8px;
      &.product-bg {
        background-repeat: no-repeat;
        background-size: 200px;
        background-position-x: right;
        // 默认图片
        .product-bg-3();
      }
      &.product-bg-0 {
        background-image: url(../../../public/img/product/bg1.png);
      }
      &.product-bg-1 {
        background-image: url(../../../public/img/product/bg2.png);
      }
      &.product-bg-2 {
        background-image: url(../../../public/img/product/bg3.png);
      }
      &.product-bg-3 {
        background-image: url(../../../public/img/product/bg4.png);
      }
      &.product-bg-4 {
        background-image: url(../../../public/img/product/bg5.png);
      }
      &.product-bg-5 {
        background-image: url(../../../public/img/product/bg6.png);
      }
    
      .body {
        p {
          display: block;
        }
        ul {
          display: block;
        }
      }
      .footer {
          .btnAdd {
            display:block;
            margin-top:30px;
          }
      }
      .header {
        h1 {
          font-size: 24px;
        }
      }
      .body {
        height: 250px;
        border-top: 1px solid #dfefed;
        padding: 20px 0 0 0;
        ul {
          display: block;
          margin: 0;
          padding: 0;
          li {
            margin: 0;
            padding: 5px 0;
            list-style: none;
            color: #303540;
            font-size: 14px;
            display: flex;
            align-items: center;
            .icon-jieshaojiantou {
              margin-right: 15px;
            }
          }
        }
      }
      .footer {
        span {
          label {
            font-size: 16px;
            color: #ff5620;
          }
          b {
            font-size: 46px;
            font-family: "DINCondensed-Bold";
            letter-spacing: -0.67px;
            font-weight: 700;
            color: #ff5620;
          }
          color: #4c4c4c;
        }
        .btnAdd {
          border: none;
          display: block;
          width: 100%;
          cursor: pointer;
          height: 45px;
          border: 1px solid @primary-color;
          background: #00A497;;
          color: #ffffff;
          font-family: "PingFang SC";
          font-weight: 500;
          border-radius: 4px;
          color: #ffffff!important;
        }
      }
    }
 }
@media screen and (min-width: 1024px) {
  .com-product-ProductItem {
        @media screen and (min-width: 320px) and (max-width: 1024px) {
             width:100%;
        }
         @media screen and(max-width: 1024px) {
              width: 350px;
         }
      
        height: 450px;
        padding: 40px 32px;
        background: #ffffff;
        border: 1px solid rgba(0, 164, 151, 0.1);
        box-shadow: 0px 0px 11px 0px rgba(0, 132, 121, 0.1);
        border-radius: 8px;
        &.product-bg {
          background-repeat: no-repeat;
          background-size: 200px;
          background-position-x: right;
          // 默认图片
          .product-bg-3();
        }
        &.product-bg-0 {
          background-image: url(../../../public/img/product/bg1.png);
        }
        &.product-bg-1 {
          background-image: url(../../../public/img/product/bg2.png);
        }
        &.product-bg-2 {
          background-image: url(../../../public/img/product/bg3.png);
        }
        &.product-bg-3 {
          background-image: url(../../../public/img/product/bg4.png);
        }
        &.product-bg-4 {
          background-image: url(../../../public/img/product/bg5.png);
        }
        &.product-bg-5 {
          background-image: url(../../../public/img/product/bg6.png);
        }
        &:hover {
          border-color: @primary-color;
          box-shadow: 0px 12px 12px 0px rgba(0, 124, 114, 0.15);
          .body {
            p {
              display: none;
            }
            ul {
              display: block;
            }
          }
          .footer {
              .btnAdd {
                background-image: linear-gradient(90deg, #00a497 0%, #02c3b8 100%);
                box-shadow: 0px 10px 26px 0px rgba(0, 164, 151, 0.37);
                color: white !important;
              }
          }
        }
        .header {
          h1 {
            font-size: 24px;
          }
        }
        .body {
          height: 250px;
          border-top: 1px solid #dfefed;
          padding: 20px 0;
          ul {
            display: none;
            margin: 0;
            padding: 0;
            li {
              margin: 0;
              padding: 5px 0;
              list-style: none;
              color: #303540;
              font-size: 14px;
              display: flex;
              align-items: center;
              .icon-jieshaojiantou {
                margin-right: 15px;
              }
            }
          }
        }
        .footer {
          span {
            label {
              font-size: 16px;
              color: #ff5620;
            }
            b {
              font-size: 46px;
              font-family: "DINCondensed-Bold";
              letter-spacing: -0.67px;
              font-weight: 700;
              color: #ff5620;
            }
            color: #4c4c4c;
          }
          .btnAdd {
            border: none;
            display: block;
            width: 100%;
            cursor: pointer;
            height: 46px;
            border: 1px solid @primary-color;
            background: white;
            color: @primary-color!important;
          }
        }
      }
    }

</style>